import CheckBox from 'components/common/checkBox/CheckBox';
import styles from './DetectionResultContainer.module.scss';
import { DetectionResultContainerProps } from './DetectionResultContainer.type';

const DetectionResultContainer = ({ children }: DetectionResultContainerProps) => {
    return (
        <div className={styles['container']}>
            <div className={styles['header']}>
                {/* <div className={styles['checkbox']}>
                    <CheckBox />
                </div> */}
                <div className={styles['name']}>Model Name (ver.)</div>
                <div className={styles['result']}>Result</div>
            </div>
            {children}
        </div>
    );
};

export default DetectionResultContainer;
