import ReactSlider from 'rc-slider';
import styles from './Slider.module.scss';
import { SliderProps } from './Slider.type';
import { useMemo, useState } from 'react';

const Slider = ({ value, width, min = 0, max = 100, onChange }: SliderProps) => {
    const [isInput, setInput] = useState(false);

    const viewingOpacity = useMemo(() => (isInput ? value : `${value}%`), [isInput, value]);

    const handleChangeSlider = (changeValue: number | number[]) => {
        if (typeof changeValue !== 'number') return;
        onChange(changeValue);
    };

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const value = target.value;

        if (!/^\d*$/.test(value)) {
            target.value = value.replace(/[^\d]/g, '');
        }

        if (Number.isNaN(target.value)) return;

        if (parseInt(value) > max) {
            target.value = max.toString();
        }

        onChange(Number(target.value));
    };

    return (
        <div className={styles['container']}>
            <div style={{ width: width ? `${width}px` : '100%' }}>
                <ReactSlider value={value} min={min} max={max} onChange={handleChangeSlider} />
            </div>
            <input
                type="text"
                className={styles['input']}
                value={viewingOpacity}
                maxLength={3}
                onFocus={() => setInput(true)}
                onBlur={() => setInput(false)}
                onInput={handleChangeInput}
            />
        </div>
    );
};

export default Slider;
