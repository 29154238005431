import { Map as OlMap } from "ol";
import Layer from "ol/layer/Layer";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import VectorSource from "ol/source/Vector";
import { create } from "zustand";

type Map = {
  map: OlMap | undefined;
  vectorSource: VectorSource | null;
  roadSource: VectorSource | null;
  buildingSource: VectorSource | null;
  changeSource: VectorSource | null;
  change2Source: VectorSource | null;
  objectSource: VectorSource | null;
  baseImageSource: XYZ | null;
  compareImageSource: XYZ | null;
  customSource: VectorSource | null;
  roadLayer: Layer | null;
  buildingLayer: Layer | null;
  changeLayer: Layer | null;
  change2Layer: Layer | null;
  objectLayer: Layer | null;
  baseImageLayer: TileLayer<XYZ> | null;
  compareImageLayer: TileLayer<XYZ> | null;
  customLayer: Layer | null;
  setVectorSource: (source: VectorSource) => void;
  setBaseImageSource: (source: XYZ) => void;
  setBaseImageLayer: (layer: TileLayer<XYZ>) => void;
  setCompareImageSource: (source: XYZ) => void;
  setCompareImageLayer: (layer: TileLayer<XYZ>) => void;
  getVectorSource: () => VectorSource | null;
  getMap: () => OlMap | undefined;
  actions: {
    roadSource: (source: VectorSource) => void;
    buildingSource: (source: VectorSource) => void;
    changeSource: (source: VectorSource) => void;
    change2Source: (source: VectorSource) => void;
    objectSource: (source: VectorSource) => void;
    customSource: (source: VectorSource) => void;
    roadLayer: (layer: Layer) => void;
    buildingLayer: (layer: Layer) => void;
    changeLayer: (layer: Layer) => void;
    change2Layer: (layer: Layer) => void;
    objectLayer: (layer: Layer) => void;
    customLayer: (layer: Layer) => void;
  }
  setMap: (map: OlMap) => void;
};


export const mapStore = create<Map>((set, get) => ({
  map: undefined,
  vectorSource: null,
  roadSource: null,
  buildingSource: null,
  changeSource: null,
  change2Source: null,
  objectSource: null,
  baseImageSource: null,
  compareImageSource: null,
  customSource: null,
  roadLayer: null,
  buildingLayer: null,
  changeLayer: null,
  change2Layer: null,
  objectLayer: null,
  baseImageLayer: null,
  compareImageLayer: null,
  customLayer: null,
  actions: {
    roadSource: (source: VectorSource) => set({ roadSource: source }),
    buildingSource: (source: VectorSource) => set({ buildingSource: source }),
    changeSource: (source: VectorSource) => set({ changeSource: source }),
    change2Source: (source: VectorSource) => set({ change2Source: source }),
    objectSource: (source: VectorSource) => set({ objectSource: source }),
    customSource: (source: VectorSource) => set({ customSource: source }),
    roadLayer: (layer: Layer) => set({ roadLayer: layer }),
    buildingLayer: (layer: Layer) => set({ buildingLayer: layer }),
    changeLayer: (layer: Layer) => set({ changeLayer: layer }),
    change2Layer: (layer: Layer) => set({ change2Layer: layer }),
    objectLayer: (layer: Layer) => set({ objectLayer: layer }),
    customLayer: (layer: Layer) => set({ customLayer: layer }),
  },
  getVectorSource: () => get().vectorSource,
  getMap: () => get().map,
  setVectorSource: (vectorSource) => set({ vectorSource }),
  setBaseImageSource: (source: XYZ) => set({ baseImageSource: source }),
  setBaseImageLayer: (layer: TileLayer<XYZ>) => set({ baseImageLayer: layer }),
  setCompareImageSource: (source: XYZ) => set({ compareImageSource: source }),
  setCompareImageLayer: (layer: TileLayer<XYZ>) => set({ compareImageLayer: layer }),
  setMap: (map) => set({ map }),
}));