import { MENU_STATE, TMainMenu, TUserMenu, USER_MENU } from 'constants/Constants';
import { useAuthentication } from 'hooks/feature/authentication/useAuthentication';
import React, { useState } from 'react';
import { TMenubar } from '../TMenubar';
import MenuItem from '../menuItem/MenuItem';
import { TUserMenuItem } from '../menuItem/MenuItem.type';
import styles from './UserMenuList.module.scss';
import MyPage from 'components/popup/myPage/MyPage';
import { redirectAdmin } from 'utils/AuthenticationUtil';
const UserMenu = (prop: TMenubar<TUserMenuItem>) => {
    const { expireToken } = useAuthentication();
    const [isShowMyPage, setShowMyPage] = useState(false);

    const onClick = <T extends TMainMenu | TUserMenu>(event: React.MouseEvent, state: T) => {
        switch (state) {
            // TODO: 관리자만 보이게 처리
            case USER_MENU.MANAGER: {
                redirectAdmin();
                break;
            }
            case USER_MENU.INFO: {
                setShowMyPage(true);
                break;
            }
            case USER_MENU.LOGOUT: {
                logout();
                break;
            }
        }
    };

    const logout = () => {
        expireToken();
    };

    return (
        <>
            <div className={styles.container}>
                {prop.itemList.length > 0 &&
                    prop.itemList.map(({ type, roles }, idx) => {
                        return <MenuItem type={type} roles={roles} key={`${type}-${idx}`} onClick={onClick} state={MENU_STATE.USER}></MenuItem>;
                    })}
            </div>
            {isShowMyPage && <MyPage onClose={() => setShowMyPage(false)} />}
        </>
    );
};

export default UserMenu;
