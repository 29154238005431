import { useMemo, useState } from 'react';
import { integerToDecimal } from 'utils/Utils';
import styles from '../Detail.module.scss';
import { DetailChildProps } from '../Detail.type';
import CustomResultControl from './control/custom/CustomResultControl';
import ResultControl from './control/ResultControl';
import Segmentation from './detectionResult/segmentation/Segmentation';

const TaskDetail = ({ result, customLayerInfos, updateCustomResultOpacity, updateShowCustomResult, updateShowResult, updateShowImage, updateResultOpacity, updateImageOpacity, getCustomName }: DetailChildProps) => {
    const [isShowResult, setShowResult] = useState(true);
    const [resultOpacity, setResultOpacity] = useState(100);
    const [isShowImage, setShowImage] = useState(true);
    const [imageOpacity, setImageOpacity] = useState(100);

    const onChangeResultOpacity = (opacity: number) => {
        setResultOpacity(opacity);
        updateResultOpacity && updateResultOpacity(integerToDecimal(opacity, 2));
    }

    const onChangeImageOpacity = (opacity: number) => {
        setImageOpacity(opacity);
        updateImageOpacity && updateImageOpacity(integerToDecimal(opacity, 2));
    }

    const onChangeResultVisible = (is: boolean) => {
        setShowResult(is);
        updateShowResult && updateShowResult(is);
    }

    const onChangeImageVisible = (is: boolean) => {
        setShowImage(is);
        updateShowImage && updateShowImage(is);
    }

    const initCustomResult = useMemo(() => {
        if (!customLayerInfos || customLayerInfos.length === 0) return;
        return (
            updateShowCustomResult && updateCustomResultOpacity && getCustomName && customLayerInfos.map((layer, index) => {
                return (<div key={layer.name}>
                    <CustomResultControl title={layer.name} layerName={getCustomName(index)} featureCount={layer.featureCount} onChangeCheck={updateShowCustomResult} onChangeOpacity={updateCustomResultOpacity}></CustomResultControl>
                </div>)
            })
        )
    }, [customLayerInfos])

    return (
        <>
            {result.featureCollection.features.length !== 0 && (
                <ResultControl
                    title="Detection Result"
                    checked={isShowResult}
                    opacity={resultOpacity}
                    onChangeCheck={onChangeResultVisible}
                    onChangeOpacity={onChangeResultOpacity}
                >
                    <Segmentation data={result} />
                </ResultControl>
            )}
            <div className={styles['custom-panel']}>
                {initCustomResult}
            </div>
            <ResultControl title="Data Images" checked={isShowImage} opacity={imageOpacity} onChangeCheck={onChangeImageVisible} onChangeOpacity={onChangeImageOpacity} />
        </>
    );
};

export default TaskDetail;
