import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Dim from '../dim/Dim';
import styles from './MyPage.module.scss';
import { MyPageProps } from './MyPage.type';
import { useEffect, useState } from 'react';
import { useMember } from 'hooks/feature/useMember';
import { TMember } from 'types/TMember';
import Button from 'components/common/button/Button';
import { PAGE_PATH } from 'constants/Constants';

const MyPage = ({ onClose }: MyPageProps) => {
    const { t } = useTranslation();
    const { getMember } = useMember();
    const [member, setMember] = useState<TMember>({
        nickname: '',
        username: '',
        email: '',
    });

    useEffect(() => {
        handleFetchMember();
    }, []);

    const handleFetchMember = async () => {
        setMember(await getMember());
    };

    const handleClose = () => {
        onClose();
    };

    const handleClickChangePassword = () => {
        window.location.href = `${process.env.REACT_APP_EE_ADMIN_URL!}${PAGE_PATH.ACCOUNT_PASSWORD}`;
    };

    return (
        <Dim>
            <div className={styles['my-page__container']}>
                <div className={styles['my-page__header-wrap']}>
                    <span className={styles['title']}>{t('mypage.title')}</span>
                    <span className={styles['icon']} onClick={handleClose}></span>
                </div>
                <div className={styles['my-page__body-wrap']}>
                    <div className={styles['title']}>{t('mypage.information')}</div>
                    <div className={styles['item']}>
                        <span className={styles['label']}>{t('mypage.name')}</span>
                        <span className={styles['value']}>{member.nickname}</span>
                    </div>
                    <div className={styles['item']}>
                        <span className={styles['label']}>{t('mypage.id')}</span>
                        <span className={styles['value']}>{member.username}</span>
                    </div>
                    <div className={styles['item']}>
                        <span className={styles['label']}>{t('mypage.email')}</span>
                        <span className={styles['value']}>{member.email}</span>
                    </div>
                    <div className={styles['item']}>
                        <span className={styles['label']}>{t('mypage.password')}</span>
                        <span>
                            <Button colorType="white" width={144} height={36} onClick={handleClickChangePassword}>
                                {t('mypage.change_password')}
                            </Button>
                        </span>
                    </div>
                </div>
                <div className={styles['my-page__footer-wrap']}>
                    <button className={classNames(styles['btn'], styles['btn-confirm'])} onClick={handleClose}>
                        {t('text_confirm')}
                    </button>
                </div>
            </div>
        </Dim>
    );
};

export default MyPage;
