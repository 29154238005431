import ResultOverlay from 'components/overlay/result/ResultOverlay';
import { MAP_OPTIONS } from 'constants/Constants';
import { OlDefaultView } from 'data/Ol';
import { useAddress } from 'hooks/feature/useAddress';
import { MapBrowserEvent, Map as OlMap, Overlay, View } from 'ol';
import { defaults } from 'ol/control/defaults';
import { Coordinate } from 'ol/coordinate';
import { FeatureLike } from 'ol/Feature';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { OSM, XYZ } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { mapStore } from 'stores/Map';
import { convert3857To4326 } from 'utils/OlUtil';
import { LAYER_FACTORY, TLayerFactory, WebGLLayerFactory } from 'utils/WebGLLayerFactory';
import styles from './OlLayer.module.scss';
import ViewControl from './viewControl/ViewControl';
import ZoomControl from './zoomControl/ZoomControl';

const OlLayer = () => {
    const mapRef = useRef<HTMLDivElement>(null);
    const { setMap, setVectorSource, actions, setBaseImageLayer, setBaseImageSource, setCompareImageLayer, setCompareImageSource } = mapStore();
    const { fetchAddress } = useAddress();

    useEffect(() => {
        initOlMap();
    }, []);

    const initTileLayer = () => {
        return new TileLayer({
            source: new OSM({ attributions: '' }),
        });
    };

    const initBaseImageLayer = () => {
        const source = new XYZ({ url: '', maxZoom: MAP_OPTIONS.TILE_MAX_ZOOM });
        const layer = new TileLayer({ source });
        setBaseImageSource(source);
        setBaseImageLayer(layer);
        return layer;
    };

    const initCompareImageLayer = () => {
        const source = new XYZ({ url: '', maxZoom: MAP_OPTIONS.TILE_MAX_ZOOM });
        const layer = new TileLayer({ source });
        setCompareImageSource(source);
        setCompareImageLayer(layer);
        return layer;
    };

    const initVectorLayer = () => {
        const source = new VectorSource({ wrapX: false });
        setVectorSource(source);
        return new VectorLayer({ source });
    };

    const initGLLayer = (type: TLayerFactory, subName: string = '') => {
        const source = new VectorSource({ wrapX: false });
        source.setProperties({ name: `${type}${subName}` });
        actions[`${type}Source`](source);
        const layer = new WebGLLayerFactory().getGLLayerByType(type, { source });
        actions[`${type}Layer`](layer);
        return layer;
    };

    const initOlMapParam = () => {
        return {
            controls: defaults({ zoom: false, rotate: false, attribution: false }),
            layers: [
                initTileLayer(),
                initBaseImageLayer(),
                initCompareImageLayer(),
                initVectorLayer(),
                // initGLLayer(LAYER_FACTORY.ROAD),
                initGLLayer(LAYER_FACTORY.SEGMENTATION),
                initGLLayer(LAYER_FACTORY.OBJECT),
                initGLLayer(LAYER_FACTORY.CHANGE),
            ],
            view: new View(OlDefaultView),
        };
    };

    const initOlMap = () => {
        const map = new OlMap(initOlMapParam());
        map.setTarget(mapRef.current || '');
        // (window as any).map = map;
        setMap(map);

        map.on('singleclick', onClickFeatures);

        return () => {
            map.setTarget('');
        };
    };

    const onClickFeatures = (event: MapBrowserEvent<MouseEvent>) => {
        if (!event.map) return;
        event.map.getOverlays().clear();
        event.map.forEachFeatureAtPixel(event.pixel, async function (feature) {
            const vec2 = event.coordinate;
            const resolution = event.map.getView().getResolution();
            resolution && (vec2[1] += (8 * resolution));
            const address = await getAddress(event.coordinate);
            event.map.addOverlay(drawOverlay(feature, event.coordinate, address));
            return true;
        });
    }

    const drawOverlay = (feature: FeatureLike, position: Coordinate, address: string | null) => {
        const overlayContainer = document.createElement('div');
        const overlayRoot = ReactDOM.createRoot(overlayContainer);
        overlayRoot.render(<ResultOverlay feature={feature} coordinates={convert3857To4326(position)} address={address} />);

        return new Overlay({
            element: overlayContainer,
            position,
            positioning: 'bottom-center',
        });
    };

    const getAddress = async (coordinate: Coordinate) => {
        const convertedCoordinate = convert3857To4326(coordinate);
        const address = await fetchAddress(convertedCoordinate[0], convertedCoordinate[1]);
        return address.data.documents.length > 0 ? address.data.documents[0].address.address_name : null;
    }

    return (
        <div className={styles.container} ref={mapRef}>
            <ZoomControl />
            <ViewControl />
        </div>
    );
};

export default OlLayer;
