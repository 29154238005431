import { OL_CHANGE_DETEACTION_STROKE_RESOLUTION, OL_CUSTOM_STROKE_COLOR_ARR, OL_SEGMENTATION_STROKE_RESOLUTION, OL_STROKE_COLOR, OL_STROKE_WIDTH } from 'constants/Constants';
import Layer from 'ol/layer/Layer';
import { FrameState } from 'ol/Map';
import WebGLVectorLayerRenderer from 'ol/renderer/webgl/VectorLayer.js';

export const LAYER_FACTORY = {
    // BUILDING: 'building',
    // ROAD: 'road',
    SEGMENTATION: 'building',
    CHANGE: 'change',
    OBJECT: 'object',
    CUSTOM: 'custom'
} as const;
export type TLayerFactory = (typeof LAYER_FACTORY)[keyof typeof LAYER_FACTORY];

export class WebGLLayerFactory {
    getGLLayerByType(type: TLayerFactory, options: any, index?: number) {
        return type === LAYER_FACTORY.CUSTOM && index ? FactoryMap[type](options, index) : FactoryMap[type](options);
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FactoryMap = {
    building(options: any) {
        return new BuildingSegmentationLayer(options);
    },
    road(options: any) {
        return new LoadSegmentationLayer(options);
    },
    change(options: any) {
        return new ChangeDetectionLayer(options);
    },
    object(options: any) {
        return new ObjectDetectionLayer(options);
    },
    custom(options: any, index?: number) {
        return new CustomLayer(options, index as number);
    }
};

export const BBOX_STYLE = {
    'stroke-color': ['*', ['get', 'COLOR'], OL_STROKE_COLOR.BBOX],
    'stroke-width': OL_STROKE_WIDTH.BBOX
} as const;

const LOAD_STYLE = {
    // 'fill-color': ['*', ['get', 'COLOR'], [255, 255, 255, 0.15]],
    'stroke-color': ['*', ['get', 'COLOR'], OL_STROKE_COLOR.ROAD_SEGMENTATION],
    'stroke-width': ['interpolate', ['exponential', 2], ['zoom'], ...OL_SEGMENTATION_STROKE_RESOLUTION]
};

const BUILDING_STYLE = {
    'fill-color': ['*', ['get', 'COLOR'], [255, 255, 255, 0.15]],
    'stroke-color': ['*', ['get', 'COLOR'], OL_STROKE_COLOR.BUILDING_SEGMENTATION],
    'stroke-width': ['interpolate', ['exponential', 2], ['zoom'], ...OL_SEGMENTATION_STROKE_RESOLUTION]
};
const OBJECT_STYLE = {
    'stroke-color': ['*', ['get', 'COLOR'], OL_STROKE_COLOR.OBJECT_DETECTION],
    'stroke-width': OL_STROKE_WIDTH.OBJECT_DETECTION,
    'fill-color': ['*', ['get', 'COLOR'], [255, 255, 255, 0.15]],
};
const CHANGE_STYLE = {
    'radius': ['interpolate', ['exponential', 2], ['zoom'], 11.5, 0.5, 18, 3.6, 21, 13.5],
    'fill-color': ['*', ['get', 'COLOR'], [255, 255, 255, 0.15]],
    'stroke-color': OL_STROKE_COLOR.CHANGE_DETECTION,
    'stroke-width': ['interpolate', ['exponential', 2], ['zoom'], ...OL_CHANGE_DETEACTION_STROKE_RESOLUTION]
}

class ExtendsWebGLLayerRenderer extends WebGLVectorLayerRenderer {
    override prepareFrame(frameState: FrameState): boolean {
        try {
            super.prepareFrame(frameState);
        } catch (e) {
            console.log(e);
        } finally {
            return true;
        }
    }

    override prepareFrameInternal(frameState: FrameState) {
        try {
            super.prepareFrameInternal(frameState);
        } catch (e) {
            console.log(e);
        } finally {
            return true;
        }
    }
}

class ChangeDetectionLayer extends Layer {
    createRenderer(): ExtendsWebGLLayerRenderer {
        return new ExtendsWebGLLayerRenderer(this, { style: CHANGE_STYLE });
    }
}

class ObjectDetectionLayer extends Layer {
    createRenderer(): WebGLVectorLayerRenderer {
        return new WebGLVectorLayerRenderer(this, { style: OBJECT_STYLE });
    }
}

class LoadSegmentationLayer extends Layer {
    createRenderer(): WebGLVectorLayerRenderer {
        return new WebGLVectorLayerRenderer(this, { style: LOAD_STYLE });
    }
}

class BuildingSegmentationLayer extends Layer {
    createRenderer(): WebGLVectorLayerRenderer {
        return new WebGLVectorLayerRenderer(this, { style: BUILDING_STYLE });
    }
}

class CustomLayer extends Layer {
    constructor(options: any, private styleIndex: number) {
        super(options);
    }
    createRenderer(): WebGLVectorLayerRenderer {
        const CUSTOM_STYLE = {
            'fill-color': ['*', ['get', 'COLOR'], [255, 255, 255, 0.15]],
            'stroke-color': ['*', ['get', 'COLOR'], OL_CUSTOM_STROKE_COLOR_ARR[this.styleIndex ?? 0]],
            'stroke-width': ['interpolate', ['exponential', 2], ['zoom'], ...OL_SEGMENTATION_STROKE_RESOLUTION],
        }
        return new WebGLVectorLayerRenderer(this, { style: CUSTOM_STYLE });
    }
}
