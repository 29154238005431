import { USER_ROLE, UserRoles } from 'types/authentication/token';

export const LANGUAGE = 'lang';
export const NONE = 'none';
export const DATE_FORMAT = 'YYYY.MM.DD';
export const TOAL_BOX_HEIGHT = 58;
export const FEATURE_SPLIT_LENGTH = 2000;
export const PROJTECT_ID = 'projectId';
export const REQUEST_ID = 'requestId';
export const VIEW = 'view';
export const Language = {
    KO: 'ko',
    EN: 'en',
} as const;

export const OL_STROKE_WIDTH = {
    BBOX: 6,
    OBJECT_DETECTION: 3,
    SEGMENTATION: 3,
};

export const OL_STROKE_COLOR = {
    BBOX: '#4CFFEA',
    OBJECT_DETECTION: '#43CA92',
    BUILDING_SEGMENTATION: '#27C4D5',
    ROAD_SEGMENTATION: '#E8E853',
    SEGMENTATION: '#27C4D5',
    CHANGE_DETECTION: '#EBE52F',
} as const;

export const OL_CUSTOM_STROKE_COLOR_ARR = [
    '#30F572', '#FF00FF', '#F97178', '#BB00FF', '#ED5F00', '#BB00FF', '#005FFF', '#EF37FF', '#EA9C00', '#BD6CFF', '#DB3008', '#10EC00'
];

export type TOlStrokeColor = (typeof OL_STROKE_COLOR)[keyof typeof OL_STROKE_COLOR];

export const OL_SEGMENTATION_STROKE_RESOLUTION = [17, 1, 18, 2, 19, 3, 20, 3.5, 21, 4];

export const OL_CHANGE_DETEACTION_STROKE_RESOLUTION = [18, 2, 19, 6, 21, 10];

export const HEADER_MEMBER_ID = 'dabeeo-member-id';

export const URL_PARAM_KEYS = {
    REFRESH_TOKEN: 'refreshToken',
};

export const LOCAL_STORAGE_KEYS = {
    EE_REFRESH_TOKEN: 'EE_REFRESH',
    EE_ACCESS_TOKEN: 'EE_ACCESS',
};

export const HTTP_HEADER_ACCESS_TOKEN = 'Authorization';

export const MENU_STATE = {
    MAIN: 'main',
    USER: 'user',
} as const;

export type TMenuState = (typeof MENU_STATE)[keyof typeof MENU_STATE];

export const MAIN_MENU = {
    RESULT: 'Result',
    REQUEST: 'Request',
} as const;
export type TMainMenu = (typeof MAIN_MENU)[keyof typeof MAIN_MENU];

export const USER_MENU = {
    MANAGER: 'manager',
    INFO: 'info',
    LOGOUT: 'logout',
} as const;
export type TUserMenu = (typeof USER_MENU)[keyof typeof USER_MENU];

export const USER_MENU_ROLE: {
    [menu in (typeof USER_MENU)[keyof typeof USER_MENU]]: UserRoles[];
} = {
    [USER_MENU.MANAGER]: [USER_ROLE.PROJECT_ADMIN, USER_ROLE.SUPER_ADMIN],
    [USER_MENU.INFO]: [],
    [USER_MENU.LOGOUT]: [],
} as const;

export type TUserMenuRoles = (typeof USER_MENU_ROLE)[keyof typeof USER_MENU_ROLE];

export const MAP_OPTIONS = {
    MIN_ZOOM: 4,
    MAX_ZOOM: 24.5,
    TILE_MAX_ZOOM: 21,
    INITIAL_ZOOM: 7,
    MIN_SCALE_WIDTH: 50,
    MAX_SCALE_WIDTH: 80,
};

export const AIS_MIN_ZOOM = 8.5;

export const LAYER_PROPS = {
    LAYER_TYPE: 'LAYER_TYPE',
    LAYER_TILE_DATA_NAME: 'LAYER_TILE_DATA_NAME',
    LAYER_CUSTOM_NAME: 'LAYER_CUSTOM_NAME',
    LAYER_CLASS_TYPE: 'LAYER_CLASS_TYPE',
} as const;

export const LAYER = {
    AOI_LAYER: 'aoiLayer',
    BBOX_LAYER: 'bboxLayer',
    TILE_LAYER: 'tileLayer',
    CUSTOM_LAYER: 'customLayer'
} as const;

export const FEATURE_TYPES = {
    POLYGON: 'Polygon',
    MULTI_POLYGON: 'MultiPolygon',
    POINT: 'Point'
} as const;

export const INTERACTION_PROPS = {
    INTERACTION_TYPE: 'INTERACTION_TYPE',
} as const;

export const INTERACTION = {
    DRAW: 'draw',
    TRANSFORM: 'transform',
} as const;

export const MAP_SCALES: { value: number; displayValue: string }[] = [
    {
        value: 2,
        displayValue: '2 m',
    },
    {
        value: 5,
        displayValue: '5 m',
    },
    {
        value: 10,
        displayValue: '10 m',
    },
    {
        value: 20,
        displayValue: '20 m',
    },
    {
        value: 50,
        displayValue: '50 m',
    },
    {
        value: 100,
        displayValue: '100 m',
    },
    {
        value: 200,
        displayValue: '200 m',
    },
    {
        value: 500,
        displayValue: '500 m',
    },
    {
        value: 1000,
        displayValue: '1 km',
    },
    {
        value: 2000,
        displayValue: '2 km',
    },
    {
        value: 5000,
        displayValue: '5 km',
    },
    {
        value: 10000,
        displayValue: '10 km',
    },
    {
        value: 20000,
        displayValue: '20 km',
    },
    {
        value: 50000,
        displayValue: '50 km',
    },
    {
        value: 100000,
        displayValue: '100 km',
    },
    {
        value: 200000,
        displayValue: '200 km',
    },
    {
        value: 500000,
        displayValue: '500 km',
    },
    {
        value: 1000000,
        displayValue: '1000 km',
    },
    {
        value: 2000000,
        displayValue: '2000 km',
    },
    {
        value: 5000000,
        displayValue: '5000 km',
    },
];

//TODO:2024.07.30 commenyt by kay : bak에서 state Key로 오도록 변경되었음. 후처리 해야함.
export const STATUS = {
    ON_STANDBY: 'On Standby',
    ANALYZING: 'Analyzing',
    COMPLETED: 'Completed',
    FAILED: 'Failed'
} as const;
export type TStatus = keyof typeof STATUS;

export const STATUS_STYLE_NAME = {
    ON_STANDBY: 'standby',
    ANALYZING: 'Analyzing',
    COMPLETED: 'completed',
    FAILED: 'failed'
} as const;

export const TASK = {
    OBJECT_DETECTION: 'Object Detection',
    SEGMENTATION: 'Segmentation',
    CHANGE_DETECTION: 'Change Detection',
} as const;

export const TASK_NAME = {
    OBJECT_DETECTION: 'object',
    SEGMENTATION: 'segmentation',
    CHANGE_DETECTION: 'change',
} as const;

export type TTask = (typeof TASK)[keyof typeof TASK];
export type TTaskKey = keyof typeof TASK;

export const CHANGE_DETECTION_POSITION = {
    LEFT: 'left',
    RIGHT: 'right',
};

export type ChangeDetectionPosition = (typeof CHANGE_DETECTION_POSITION)[keyof typeof CHANGE_DETECTION_POSITION];

export const KEYS = {
    ENTER: 'Enter',
    ESCAPE: 'Escape',
};

export const GEO_JSON_PROPERTIES = {
    CLASS: 'class',
    AREA: 'area',
} as const;

export const PAGE_PATH = {
    ACCOUNT_PASSWORD: '/account/password'
}