import classNames from 'classnames';
import { GEO_JSON_PROPERTIES } from 'constants/Constants';
import { useTranslation } from 'react-i18next';
import styles from './ResultOverlay.module.scss';
import { ResultOverlayProps } from './ResultOverlay.type';

const ResultOverlay = ({ feature, coordinates, address }: ResultOverlayProps) => {
    const { t } = useTranslation();

    const handleCopyCoordinates = async (type: 'Coordinate' | 'Address') => {
        const value = type === 'Coordinate' ? `${coordinates[1].toFixed(6)},${coordinates[0].toFixed(6)}` : address || '';
        try {
            await navigator.clipboard.writeText(value);
        } catch (e) {
            const id = 'dummy';
            const input = document.createElement('input');
            input.type = 'text';
            input.id = id;
            input.value = value;
            input.style.position = 'fixed';
            input.style.top = '-100px';
            document.body.appendChild(input);

            const obj = document.getElementById(id);
            if (!(obj instanceof HTMLInputElement)) return;
            obj.select();
            document.execCommand('copy');
            document.body.removeChild(obj);
        }
    };

    return (
        <div className={styles['overlay-conatainer']}>
            <div className={styles['category']}>
                <div className={classNames(styles['title'], styles['coordinates'])}>
                    <span>{t('overlay.result.coordinates')}</span>
                    <span className={styles['icon']} onClick={() => handleCopyCoordinates('Coordinate')}></span>
                </div>
                <div className={styles['value']}>
                    <div>Longitude:{coordinates[1].toFixed(6)}</div>
                    <div>Latitude:{coordinates[0].toFixed(6)}</div>
                </div>
            </div>
            {feature.get(GEO_JSON_PROPERTIES.CLASS) && (
                <div className={styles['category']}>
                    <div className={styles['title']}>
                        <span>{t('overlay.result.class')}</span>
                    </div>
                    <div className={styles['value']}>
                        <div>
                            {feature.get(GEO_JSON_PROPERTIES.CLASS)}
                        </div>
                    </div>
                </div>
            )}
            {feature.get(GEO_JSON_PROPERTIES.AREA) && (
                <div className={styles['category']}>
                    <div className={styles['title']}>
                        <span>{t('overlay.result.area')}</span>
                    </div>
                    <div className={styles['value']}>
                        <div>
                            {feature.get(GEO_JSON_PROPERTIES.AREA)}
                        </div>
                    </div>
                </div>
            )}
            {address && (
                <div className={styles['category']}>
                    <div className={classNames(styles['title'], styles['address'])}>
                        <span>{t('overlay.result.address')}</span>
                        <span className={styles['icon']} onClick={() => handleCopyCoordinates('Address')}></span>
                    </div>
                    <div className={styles['value']}>
                        <div>{address}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResultOverlay;
