
import styles from './ModelItem.module.scss';
import { TResult } from 'types/TResult';
import { LAYER_FACTORY } from 'utils/WebGLLayerFactory';
import classNames from 'classnames';
import { TASK_NAME } from 'constants/Constants';

type ModelProps = {
    data: TResult
}


const ModelItem = ({ data }: ModelProps) => {

    const getFeatureCount = () => {
        return data.featureCollection.features.length.toLocaleString('ko-KR')
    }
    return (
        <div className={styles['item-wrap']}>
            <div className={styles['model-wrap']}>
                {/* <div className={styles['checkbox']}>
                    <CheckBox />
                </div> */}
                <div className={classNames([styles['name']], styles[TASK_NAME[data.request.aiCore.task]])}>{data.request.aiCore.coreName} ({data.request.aiCore.versions[0].version})</div>
                {getFeatureCount()}
            </div>
        </div>
    );
};

export default ModelItem;
