import { VIEW } from 'constants/Constants';

export const getUrl = (endPoint: string) => {
    return `${process.env.REACT_APP_BASE_URL}/${VIEW}/${getPath()}${endPoint}`;
};

export const getKakaoUrl = (endPoint: string) => {
    return `${process.env.REACT_APP_KAKAO_URL}${endPoint}`;
}

export const getPath = (): string => {
    return window.location.pathname.replaceAll('/', '');
};

/**
 * @description 랜덤 아이디 생성
 * @returns
 */
export const makeRandomId = () => {
    let id = '';
    for (let i = 0; i < 12; i++) id += Math.floor(Math.random() * 10);
    return 'id-' + id;
};

/**
 * @description BASE URL + ENDPOINT 리턴
 * @param endpoint
 * @returns
 */
export const makeApiUri = (endpoint: string) => {
    return `${process.env.REACT_APP_BASE_URL}${endpoint}`;
};

export const integerToDecimal = (value: number, digit: number) => parseFloat((value / 100).toFixed(digit));

export const debounce = <T extends (...args: any[]) => any>(fn: T, delay: number) => {
    let timeout: ReturnType<typeof setTimeout>;

    return (...args: Parameters<T>): ReturnType<T> => {
        let result: any;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            result = fn(...args);
        }, delay);
        return result;
    };
};

export const downloadFile = (data: Blob, fileName: string) => {
    const href = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};
