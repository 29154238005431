import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { integerToDecimal } from 'utils/Utils';
import styles from '../Detail.module.scss';
import { DetailChildProps } from '../Detail.type';
import CustomResultControl from '../task/control/custom/CustomResultControl';
import ResultControl from '../task/control/ResultControl';
import DetectionResult from './detectionResult/DetectionResult';
import Information from './information/Information';
import Swipe from './swipe/Swipe';

//Dispatch<SetStateAction<number>>

const ChangeDetection = ({ swipeRef, result, customLayerInfos, updateShowCustomResult, getCustomName, updateCustomResultOpacity, rerender, drawCompareImage, updateShowResult, updateShowImage, updateResultOpacity, updateImageOpacity }: DetailChildProps) => {
    const [swipe, setSwipevalue] = useState<number>(50);
    const [isShowResult, setShowResult] = useState(true);
    const [resultOpacity, setResultOpacity] = useState(100);
    const [isShowImage, setShowImage] = useState(true);
    const [imageOpacity, setImageOpacity] = useState(100);

    useEffect(() => {
        drawCompareImage && drawCompareImage(result.request.requestImageDataList[1].imageTileUrl);
    }, [])


    const hanldeChangSwipeValue = (value: number) => {
        swipeRef && (swipeRef.current = value);
        setSwipevalue(value);
        rerender && rerender();
    };

    const initCustomResult = useMemo(() => {
        if (!customLayerInfos || customLayerInfos.length === 0) return;
        return (
            updateShowCustomResult && updateCustomResultOpacity && getCustomName && customLayerInfos.map((layer, index) => {
                return (<div key={layer.name}>
                    <CustomResultControl title={layer.name} layerName={getCustomName(index)} featureCount={layer.featureCount} onChangeCheck={updateShowCustomResult} onChangeOpacity={updateCustomResultOpacity}></CustomResultControl>
                </div>)
            })
        )
    }, [customLayerInfos])

    const onChangeImageOpacity = (opacity: number) => {
        setImageOpacity(opacity);
        updateImageOpacity && updateImageOpacity(integerToDecimal(opacity, 2));
    }

    const onChangeImageVisible = (is: boolean) => {
        setShowImage(is);
        updateShowImage && updateShowImage(is);
    }

    return (
        <>
            <Information request={result.request} />
            {result.featureCollection.features.length !== 0 && (
                <DetectionResult data={result} updateResultOpacity={updateResultOpacity} updateShowResult={updateShowResult} updateImageOpacity={updateImageOpacity} updateShowImage={updateShowImage} />
            )}
            <div className={styles['custom-panel']}>
                {initCustomResult}
            </div>
            <ResultControl title="Data Images" checked={isShowImage} opacity={imageOpacity} onChangeCheck={onChangeImageVisible} onChangeOpacity={onChangeImageOpacity} />
            {createPortal(<Swipe value={swipe} onChange={hanldeChangSwipeValue} />, document.getElementById('map-slider-root')!)}

        </>
    );
};

export default ChangeDetection;
