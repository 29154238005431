import CheckBox from 'components/common/checkBox/CheckBox';
import { useState } from 'react';
import { integerToDecimal } from 'utils/Utils';
import styles from '../ResultControl.module.scss';
import { CustomResultControlProps } from './CustomResultControl.type';

const CustomResultControl = ({ title, children, layerName, featureCount, onChangeCheck, onChangeOpacity }: CustomResultControlProps) => {
    const [isOpen, setOpen] = useState(true);
    const [isShowResult, setShowResult] = useState(true);
    const [resultOpacity, setResultOpacity] = useState(100);

    const onChangeCustomVisible = (is: boolean) => {
        setShowResult(is);
        onChangeCheck && layerName && onChangeCheck(layerName, is);
    }

    const onChangeCustomOpacity = (opacity: number) => {
        setResultOpacity(opacity);
        onChangeOpacity && layerName && onChangeOpacity(layerName, integerToDecimal(opacity, 2));
    }

    const getCount = () => {
        return featureCount.toLocaleString('ko-KR');
    }

    return (
        <div className={styles['container']}>
            <div className={styles['title-area']}>
                <div className={styles['title']}>
                    <CheckBox label={title} checked={isShowResult} onChange={(e) => onChangeCustomVisible(e.target.checked)} colorIndex={Number(layerName.charAt(layerName.length - 1))} />
                </div>
                <div className={styles['count']}>{getCount()}</div>
                {/* <FoldButton open={isOpen} onClick={() => setOpen(!isOpen)} /> */}
            </div>
            {/* <div className={classNames({ [styles['folded']]: !isOpen })}>
                <OpacityControl opacity={resultOpacity} onChange={onChangeCustomOpacity} />
            </div> */}
            {children}
        </div>
    );
};

export default CustomResultControl;

