import React, { useEffect, useState } from 'react';
import styles from './Header.module.scss';
import { SelectedProjectStore } from 'stores/SelectedProject';

const Header = () => {
    const { project } = SelectedProjectStore();
    const [logo, setLogo] = useState<string>("");
    useEffect(() => {
        project && setLogo(project.logoUrl ?? "");
    }, [project]);

    return <div className={`${styles.header} ${styles['-basic']}`}>
        {project?.logoUrl ? <img src={logo} alt="" /> : <span className={styles.logo} />}
    </div>
}

export default Header;