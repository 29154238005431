// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Pretendard, Pretendard Variable", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .react-datepicker {        
    .react-datepicker__day--outside-month {
      cursor: default;
      visibility: hidden;      
    }
    .react-datepicker__day--today {      
      color: #4F74F9;
    }
  }
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ol-overlaycontainer-stopevent {
  z-index: 1 !important;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,gQAAgQ;EAChQ,mCAAmC;EACnC,kCAAkC;EAClC;IACE;MACE,eAAe;MACf,kBAAkB;IACpB;IACA;MACE,cAAc;IAChB;EACF;EACA,gBAAgB;AAClB;;AAEA;EACE;aACW;AACb;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Pretendard, Pretendard Variable\", -apple-system, BlinkMacSystemFont, system-ui, Roboto, \"Helvetica Neue\", \"Segoe UI\", \"Apple SD Gothic Neo\", \"Noto Sans KR\", \"Malgun Gothic\", \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  .react-datepicker {        \n    .react-datepicker__day--outside-month {\n      cursor: default;\n      visibility: hidden;      \n    }\n    .react-datepicker__day--today {      \n      color: #4F74F9;\n    }\n  }\n  overflow: hidden;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.ol-overlaycontainer-stopevent {\n  z-index: 1 !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
