import { END_POINT } from 'constants/Api';
import { TFilter } from 'types/TFilter';
import { TResult } from 'types/TResult';
import { TRequestInfo } from 'types/TRequest';
import { getUrl } from 'utils/Utils';
import api from './Instance';

const setArrayParam = (key: string, list: Array<string>) => {
    return list.reduce((str, value) => {
        return `${str}&${key}=${value}`;
    }, '');
};

const createRequestParam = (id: number, filter: TFilter) => {
    const param = Array.from(Object.entries(filter)).reduce((str, [key, value]) => {
        switch (key) {
            case 'task':
            case 'status': {
                return Array.isArray(value) ? `${str}${setArrayParam(key, value)}` : str;
            }
            default: {
                return `${str}&${key}=${value}`;
            }
        }
    }, '');
    return `${id}?${param}`;
};

export const requestListBy = async (id: number, filter: TFilter) => {
    let response = null;
    try {
        response = await api.get<TRequestInfo>(`${getUrl(END_POINT.REQUEST_LIST)}/${createRequestParam(id, filter)}`);
    } catch (e) {
        console.warn(e);
    } finally {
        return response;
    }
};

export const detail = async (requestId: number) => {
    const response = await api.get<TResult>(`${getUrl(END_POINT.DETAIL)}/${requestId}`);
    return response;
};
