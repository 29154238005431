import classNames from 'classnames';
import CheckBox from 'components/common/checkBox/CheckBox';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TResult } from 'types/TResult';
import { integerToDecimal } from 'utils/Utils';
import FoldButton from '../../common/foldButton/FoldButton';
import OpacityControl from '../../common/opacityControl/OpacityControl';
import Segmentation from '../../task/detectionResult/segmentation/Segmentation';
import styles from './DetectionResult.module.scss';

type Props = {
    data: TResult;
    updateShowResult?: (is: boolean) => void;
    updateShowImage?: (is: boolean) => void;
    updateResultOpacity?: (opacity: number) => void;
    updateImageOpacity?: (opacity: number) => void;
}

const DetectionResult = ({ data, updateShowResult, updateResultOpacity }: Props) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(true);
    const [resultChecked, setShowResult] = useState(true);
    const [resultOpacity, setResultOpacity] = useState(100);

    const onChangeResultOpacity = (opacity: number) => {
        setResultOpacity(opacity);
        updateResultOpacity && updateResultOpacity(integerToDecimal(opacity, 2));
    }

    const onChangeResultVisible = (is: boolean) => {
        setShowResult(is);
        updateShowResult && updateShowResult(is);
    }

    return (
        <div className={styles['container']}>
            <div className={styles['title-area']}>
                <div className={styles['title']}>{t('panel.change_detection.detection_result')}</div>
                <FoldButton open={isOpen} onClick={() => setOpen(!isOpen)} />
            </div>
            <div className={classNames({ [styles['folded']]: !isOpen })}>
                <div className={styles['control-area']}>
                    <div className={styles['title']}>
                        <CheckBox label={t('panel.change_detection.result')} checked={resultChecked} onChange={(e) => onChangeResultVisible(e.target.checked)} />
                    </div>
                    <OpacityControl opacity={resultOpacity} onChange={onChangeResultOpacity} />
                    <Segmentation data={data} />
                </div>
            </div>
        </div>
    );
};

export default DetectionResult;
