import axios from 'axios';
import { FEATURE_SPLIT_LENGTH, LAYER, LAYER_PROPS } from 'constants/Constants';
import { OlDefaultView } from 'data/Ol';
import { Feature } from 'ol';
import GeoJSON, { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { Geometry, MultiPolygon } from 'ol/geom';
import Polygon from 'ol/geom/Polygon';
import { SketchCoordType } from 'ol/interaction/Draw.js';
import Layer from 'ol/layer/Layer';
import { getRenderPixel } from 'ol/render';
import RenderEvent from 'ol/render/Event';
import { XYZ } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { useCallback, useRef, useState } from 'react';
import { CustomLayerInfo, customLayerStore } from 'stores/CustomLayer';
import { mapStore } from 'stores/Map';
import { SelectedRequestStore } from 'stores/SelectedRequest';
import { TRequestContent } from 'types/TRequest';
import { FeatureProperties, TChangeDetectFeature, TFeature, TResult } from 'types/TResult';
import { convert4326To3857, onUpdateBBox } from 'utils/OlUtil';
import { LAYER_FACTORY, TLayerFactory, WebGLLayerFactory } from 'utils/WebGLLayerFactory';

export const useOpenLayers = () => {
    const { getMap, map, roadSource, buildingSource, changeSource, change2Source, objectSource, customSource, customLayer, change2Layer, roadLayer, buildingLayer, changeLayer, objectLayer, baseImageLayer, baseImageSource, compareImageLayer, compareImageSource, getVectorSource } = mapStore();
    const { getSelected } = SelectedRequestStore();
    const { setCustomLayerInfos } = customLayerStore();
    const [layer, setCurrentLayer] = useState<Layer | null>(null);
    const [addedLayer, setAddedLayer] = useState<Array<Layer>>(new Array());
    const [addedCustomLayer, setAddedCustomLayer] = useState<Array<Layer>>(new Array());
    const swipeRef = useRef<number>(50);

    const sourceMapper = {
        object: objectSource,
        change: changeSource,
        change2: change2Source,
        road: roadSource,
        building: buildingSource,
        custom: customSource,
    };

    const layerMapper = {
        object: objectLayer,
        change: changeLayer,
        change2: change2Layer,
        road: roadLayer,
        building: buildingLayer,
        custom: customLayer
    };

    const updateCustomFeatureOpacity = (layerName: string, opacity: number) => {
        const layers = findLayers(LAYER_PROPS.LAYER_CUSTOM_NAME, layerName);
        const otherCustomLayers = findLayers(LAYER_PROPS.LAYER_TYPE, LAYER.CUSTOM_LAYER)?.filter(i => i.get(LAYER_PROPS.LAYER_CUSTOM_NAME) !== layerName);
        // const zindex = Number(layerName.charAt(layerName.length - 1));
        // otherCustomLayers?.forEach((layer) => {
        //     console.log('other get', layer.getZIndex());
        //     layer.setZIndex(10);
        // })
        
        layers?.forEach((layer) => {
            // console.log('get', layer.getZIndex());
            layer.setOpacity(opacity);
        });

        
        
    }

    const updateCustomFeatureVisible = (layerName: string, is: boolean) => {
        const layers = findLayers(LAYER_PROPS.LAYER_CUSTOM_NAME, layerName);
        layers?.forEach((layer) => {
            layer.setVisible(is);
        })
    }

    const updateFeatureOpacity = (opacity: number) => {
        layer?.setOpacity(opacity);
        addedLayer.forEach((layer) => {
            layer.setOpacity(opacity);
        });
    };

    const updateFeaturesVisible = (is: boolean) => {
        layer?.setVisible(is);
        addedLayer.forEach((layer) => {
            layer.setVisible(is);
        });
    };

    const updateBaseImageOpacity = (opacity: number) => {
        baseImageLayer?.setOpacity(opacity);
        compareImageLayer?.hasListener('prerender') && compareImageLayer.setOpacity(opacity);
    };

    const updateBaseImageVisible = (is: boolean) => {
        baseImageLayer?.setVisible(is);
        compareImageLayer?.hasListener('prerender') && compareImageLayer.setVisible(is);
    };

    const drawRectangle = (coordinates: SketchCoordType) => {
        const start = coordinates[0] as number[];
        const end = coordinates[1] as number[];
        const minX = Math.min(start[0], end[0]);
        const maxX = Math.max(start[0], end[0]);
        const minY = Math.min(start[1], end[1]);
        const maxY = Math.max(start[1], end[1]);

        return new Polygon([
            [
                [minX, minY],
                [maxX, minY],
                [maxX, maxY],
                [minX, maxY],
                [minX, minY],
            ],
        ]);
    };

    const drawBBox = (request: TRequestContent, isFit: boolean = true) => {
        clearAll();
        const first = request.requestImageDataList[0];
        if (!first) return;
        const geometry = drawRectangle([convert4326To3857([first.swLng, first.swLat]), convert4326To3857([first.neLng, first.neLat])]);
        const feature = new Feature({ geometry, name: request.name });
        feature.setStyle(onUpdateBBox);
        getVectorSource()?.addFeature(feature);        
        isFit && getMap()?.getView().fit(geometry, { padding: [170, 50, 150, 500] });
    };

    const createChangeDetectFeatures = (features: Array<GeoJSONFeatureCollection>) => {
        return features.map((feat: TChangeDetectFeature) => {
            const geo = new MultiPolygon(
                feat.geometry.coordinates.map((coords) => {
                    return coords.map((multiPoly) => {
                        return multiPoly.map((vec) => {
                            return convert4326To3857(vec);
                        });
                    });
                })
            );

            const feature = new Feature(geo);
            setupPropertiesAtFeature(feature, feat.properties);
            return feature;
        });
    };

    const createFeatures = (features: Array<GeoJSONFeatureCollection>) => {
        return features.map((feat: TFeature) => {
            const geo = new Polygon(
                feat.geometry.coordinates.map((coords) => {
                    return coords.map((vec) => {
                        return convert4326To3857(vec);
                    });
                })
            );

            const feature = new Feature(geo);
            setupPropertiesAtFeature(feature, feat.properties);
            return feature;
        });
    };

    const setupPropertiesAtFeature = (feature: Feature, properties: FeatureProperties) => {
        for (const [key, value] of Object.entries(properties)) {
            feature.set(key, value);
        }
    };

    let addedLayerList: Array<Layer> = [];
    let addedCustomLayerList: Array<Layer> = [];
    const drawFeatures = async (detail: TResult, type: TLayerFactory) => {
        clearFeatures();
        const features = new GeoJSON().readFeatures(detail.featureCollection, { featureProjection: 'EPSG:3857' });
        // const features = type === LAYER_FACTORY.CHANGE ? createChangeDetectFeatures(list) : createFeatures(list);
        //TODO:2024.07.30 comment by kay : 추후 detail feature type 또는 model type에 따라 layer 분리 해야 할 수 도 있습니다. 
        if (detail.customLayerList.length > 0) {
            await addCustomFeaturesLayer(detail.customLayerList);
        }
        if (features.length > FEATURE_SPLIT_LENGTH) {
            addSpliteFeaturesLayer(features, type);
        } else {
            sourceMapper[type]?.addFeatures(features);
            setCurrentLayer(layerMapper[type]);
        }
    };

    const drawBaseImage = (url: string) => {
        baseImageSource && baseImageSource.setUrl(url);
    };

    const drawCompareImage = (url: string) => {
        compareImageSource && compareImageSource.setUrl(url);
    };

    const addCompareLayerEvent = () => {
        compareImageLayer?.on('prerender', onPrerender);
        compareImageLayer?.on('postrender', onPostRender);
    };

    const addSpliteFeaturesLayer = (features: Feature<Geometry>[], type: TLayerFactory, index?: number) => {
        let length = features.length;
        while (length > 0) {
            if (type === LAYER_FACTORY.CUSTOM && (index === undefined || index === null)) return;
            const layer = createNewGLLayer(type, index);
            // index !== undefined && layer.setZIndex(index);
            index !== undefined && layer.set(LAYER_PROPS.LAYER_CUSTOM_NAME, getCustomLayerName(index));
            index !== undefined && layer.set(LAYER_PROPS.LAYER_TYPE, LAYER.CUSTOM_LAYER);
            const split = features.splice(0, FEATURE_SPLIT_LENGTH);
            (layer.getSource() as VectorSource).addFeatures(split);
            map?.addLayer(layer);
            index === undefined ? addedLayerList.push(layer) : addedCustomLayerList.push(layer);
            length = features.length;
        }
        setAddedLayer(addedLayerList);
        setAddedCustomLayer(addedCustomLayerList);
    };

    const addCustomFeaturesLayer = async (geojsonUrls: string[]) => {
        let customLayers: CustomLayerInfo[] = [];

        for(const url of geojsonUrls) {
            const result = await axios.get(url);
            if (result) {
                const layerInfo: CustomLayerInfo = {
                    name: result.data.name,
                    featureCount: result.data.features.length,
                };
                customLayers.push(layerInfo);
                const features = new GeoJSON().readFeatures(result.data, {featureProjection: 'EPSG:3857'});
                addSpliteFeaturesLayer(features, LAYER_FACTORY.CUSTOM, geojsonUrls.indexOf(url));
            }
        }
        setCustomLayerInfos(customLayers);
    }

    const onPrerender = useCallback((event: RenderEvent) => {
        if (!event) return;
        const ctx = event.context;
        if (!(ctx instanceof CanvasRenderingContext2D)) return;
        const mapSize = map?.getSize();
        if (typeof mapSize === 'undefined') return;

        const width = mapSize[0] * (swipeRef.current / 100);
        const tl = getRenderPixel(event, [width, 0]);
        const tr = getRenderPixel(event, [mapSize[0], 0]);
        const bl = getRenderPixel(event, [width, mapSize[1]]);
        const br = getRenderPixel(event, mapSize);

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(tl[0], tl[1]);
        ctx.lineTo(bl[0], bl[1]);
        ctx.lineTo(br[0], br[1]);
        ctx.lineTo(tr[0], tr[1]);
        ctx.closePath();
        ctx.clip();
    }, [map]);

    const onPostRender = useCallback((event: RenderEvent) => {
        if (!event) return;
        const ctx = event.context;
        if (!(ctx instanceof CanvasRenderingContext2D)) return;
        ctx.restore();
    }, [map]);

    const createNewGLLayer = (type: TLayerFactory, index?: number) => {
        const source = new VectorSource({ wrapX: false });
        const layer = new WebGLLayerFactory().getGLLayerByType(type, { source }, index);
        return layer;
    };

    const findLayer = (propName: string, value: any) => {
        const layers = map?.getAllLayers();
        return layers?.find((layer) => layer.get(propName) === value);
    }

    const findLayers = (propName: string, value: any) => {
        const layers = map?.getAllLayers();
        return layers?.filter((layer => layer.get(propName) === value));
    }

    const getCustomLayerName = (index: number) => {
        return `CUSTOM_LAYER_${index}`;
    }
      

    const clearAll = () => {
        getVectorSource()?.clear();
        clearFeatures();
        clearBaseImage();
        clearCompareImage();
        clearOverlays()
    };

    const clearFeatures = () => {
        roadSource && roadSource.clear();
        buildingSource && buildingSource.clear();
        changeSource && changeSource.clear();
        objectSource && objectSource.clear();
        addedLayer.forEach((layer) => {
            (layer.getSource() as VectorSource).clear();
            layer.dispose();
            map?.removeLayer(layer);
        });
        addedCustomLayer.forEach((layer) => {
            layer.dispose();
            map?.removeLayer(layer);
        })
        setAddedLayer(new Array());
        setAddedCustomLayer(new Array());
        addedLayerList = [];
        addedCustomLayerList = [];
        updateFeatureOpacity(100);
        updateFeaturesVisible(true);
    };

    const clearBaseImage = () => {
        if (!(baseImageSource instanceof XYZ)) return;
        baseImageSource.clear();
        baseImageSource.setUrl('');
        updateBaseImageOpacity(100);
        updateBaseImageVisible(true);
    };

    const removeCompareLayerEvent = () => {
        compareImageLayer?.hasListener('prerender') && compareImageLayer.un('prerender', onPrerender);
        compareImageLayer?.hasListener('postrender') && compareImageLayer.un('postrender', onPostRender);
    }

    const clearCompareImage = () => {
        if (!(compareImageSource instanceof XYZ)) return;
        removeCompareLayerEvent();
        compareImageSource.clear();
        compareImageSource.setUrl('');
        compareImageLayer?.setOpacity(100);
        compareImageLayer?.setVisible(true);
    };

    const clearOverlays = () => {
        map?.getOverlays().clear();
    };

    const rerender = () => {
        map?.render();
    };

    const initSwipe = () => {
        swipeRef.current && (swipeRef.current = 50);
    }

    const initMap = () => {
        map?.getView().setCenter(OlDefaultView.center)
        map?.getView().setZoom(OlDefaultView.zoom);
    }

    return {
        map,
        swipeRef,
        drawBBox,
        drawFeatures,
        drawBaseImage,
        drawCompareImage,
        updateCustomFeatureOpacity,
        updateCustomFeatureVisible,
        updateFeatureOpacity,
        updateFeaturesVisible,
        updateBaseImageOpacity,
        updateBaseImageVisible,
        findLayer,
        findLayers,
        clearAll,
        clearFeatures,
        clearBaseImage,
        clearCompareImage,
        clearOverlays,
        rerender,
        initSwipe,
        initMap,
        addCompareLayerEvent,
        removeCompareLayerEvent,
        addSpliteFeaturesLayer,
        getCustomLayerName
    };
};
