import { TResult } from 'types/TResult';
import DetectionResultContainer from '../container/DetectionResultContainer';
import ModelItem from './model/ModelItem';

type SegmentationProps = {
    data:TResult;
}

const Segmentation = ({data}:SegmentationProps) => {
    return (
        <DetectionResultContainer>
            <ModelItem data={data}/>
        </DetectionResultContainer>
    );
};

export default Segmentation;
