// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rc-slider {
    position: relative;
    width: 100%;
    height: 12px;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .rc-slider-step,
    .rc-slider-rail,
    .rc-slider-track {
        border-radius: 4px;
        height: 8px;
    }

    .rc-slider-step,
    .rc-slider-rail,
    .rc-slider-track,
    .rc-slider-handle {
        position: absolute;
    }

    .rc-slider-step,
    .rc-slider-rail {
        width: 100%;
    }

    .rc-slider-rail {
        background-color: #5E6878;

    }

    .rc-slider-track {
        background-color: #D3D6DA;
    }

    .rc-slider-handle {
        border: none;
        opacity: 1;
        width: 12px;
        height: 12px;
        background-color: #FFFFFF;
        border-radius: 50%;
        cursor: grab;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.40));
        -webkit-user-select: none;
                user-select: none;
        touch-action: pan-x;
        border: none;

        &:active {
            cursor: grabbing;
        }

        &:focus,
        &:focus-visible {
            outline: 0;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/_slider.scss"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;;IAEnB;;;QAGI,kBAAkB;QAClB,WAAW;IACf;;IAEA;;;;QAII,kBAAkB;IACtB;;IAEA;;QAEI,WAAW;IACf;;IAEA;QACI,yBAAyB;;IAE7B;;IAEA;QACI,yBAAyB;IAC7B;;IAEA;QACI,YAAY;QACZ,UAAU;QACV,WAAW;QACX,YAAY;QACZ,yBAAyB;QACzB,kBAAkB;QAClB,YAAY;QACZ,oDAAoD;QACpD,yBAAiB;gBAAjB,iBAAiB;QACjB,mBAAmB;QACnB,YAAY;;QAEZ;YACI,gBAAgB;QACpB;;QAEA;;YAEI,UAAU;QACd;IACJ;AACJ","sourcesContent":[".rc-slider {\n    position: relative;\n    width: 100%;\n    height: 12px;\n    border-radius: 6px;\n    touch-action: none;\n    box-sizing: border-box;\n    display: flex;\n    align-items: center;\n\n    .rc-slider-step,\n    .rc-slider-rail,\n    .rc-slider-track {\n        border-radius: 4px;\n        height: 8px;\n    }\n\n    .rc-slider-step,\n    .rc-slider-rail,\n    .rc-slider-track,\n    .rc-slider-handle {\n        position: absolute;\n    }\n\n    .rc-slider-step,\n    .rc-slider-rail {\n        width: 100%;\n    }\n\n    .rc-slider-rail {\n        background-color: #5E6878;\n\n    }\n\n    .rc-slider-track {\n        background-color: #D3D6DA;\n    }\n\n    .rc-slider-handle {\n        border: none;\n        opacity: 1;\n        width: 12px;\n        height: 12px;\n        background-color: #FFFFFF;\n        border-radius: 50%;\n        cursor: grab;\n        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.40));\n        user-select: none;\n        touch-action: pan-x;\n        border: none;\n\n        &:active {\n            cursor: grabbing;\n        }\n\n        &:focus,\n        &:focus-visible {\n            outline: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
